html {
	font-size: 1.15rem;
}

body {
	background-image: url("../images/Background_Grid.png");
	background-color: #cccccc;
	color: #919191;
} 

h2{
	width: 100%;
	border-bottom: 2px solid #bbb;
	position: relative;
	left: 0px;
	border-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(14,212,130,1) 1%, rgba(0,0,0,0) 60%) 1;	
	font-size: 1.7rem;
}

.full {
	box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.5);
	width: 100%;
}

.avatar {
	width: 100%;
	height: auto;      
}

.tool-icon {
	padding: 2px;
	width: 60px;
	height: 60px;
	display: inline-block;
}

.tool-icon-img {
	width: 100%;
	height: 100%;

	opacity: 0;
	animation-name: fadeIn;
	animation-duration: 0.5s;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
	animation-fill-mode: forwards;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: scale(1.4);
	}

	to {
		opacity: 1;
		transform: scale(1);
	}
}

a {
	text-decoration: none; 
	color: rgba(14,212,130,1);
}

a:hover {
	text-decoration: none; 
	color: rgba(16,227,178,1);
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}


.anchor {
	opacity: 0;
	transition: all 0.25s ease-out;
}


.anchor:hover {
	opacity: 1;
	color: rgba(14,212,130,1);
}


.navbar {
	-webkit-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.75);
}

.nav-tabs {
	--bs-nav-tabs-link-active-bg: rgba(72, 185, 253, 0);
}

.nav-tabs > .nav-item > .nav-link {
	color: rgba(14,212,130,1);
}

.navbar-brand {
	height: 50px;
	padding-left: 20px;
}

.socials {
	margin-left: auto; 
	margin-right: 30px;
}

.bg-shimmer {
	-webkit-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.75);
	background: linear-gradient(142deg, rgba(16,227,178,1) 0%, rgba(97,164,97,1) 4%, rgba(38,38,38,1) 11%, rgba(38,38,38,1) 91%, rgba(255,76,0,1) 96%, rgba(255,0,65,1) 100%);
}

.text-bg-dark {
	background-color: #262626 !important;
	color: #919191 !important;
}

.text-bg-secondary{
	background-color: #393737 !important;
	color: #757575 !important;
}


.note{
	background-color: #007868;
	color: #000000;

	border-radius: var(--bs-border-radius-lg);
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 0px;

	-webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	
}

.warning{
	background-color: #8f5601;
	color: #000000;

	border-radius: var(--bs-border-radius-lg);
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 0px;

	-webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	
}

.error{
	background-color: #8f0801;

	border-radius: var(--bs-border-radius-lg);
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 0px;

	-webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	
}


pre.highlight {
	background-color: #212121;
	border-radius: var(--bs-border-radius-lg);
	padding-top: 35px;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 0px;
	
	-webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	
}

.badge {
	padding: 10px 20px;
	-webkit-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.4);
	-moz-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.4);
	box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.4);

	background: linear-gradient(135deg, rgba(16,227,178,1) 5%, rgba(38,38,38,1) 15%); 

	transition-property: background;
	transition-duration: 0.5s;
}

.badge:hover{
	background: linear-gradient(135deg, rgba(255,76,0,1) 10%, rgba(50,50,50,1) 15%, rgba(50,50,50,1) 85%, rgba(255,76,0,1) 90%); 
}


.btn-primary {
	padding: 10px 20px;
	-webkit-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.4);
	-moz-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.4);
	box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.4);

	background: linear-gradient(135deg, rgba(16,227,178,1) 5%, rgba(38,38,38,1) 15%); 

	transition-property: background;
	transition-duration: 0.5s;
}

.btn-primary:hover{
	background: linear-gradient(135deg, rgba(255,76,0,1) 10%, rgba(50,50,50,1) 15%, rgba(50,50,50,1) 85%, rgba(255,76,0,1) 90%); 
}

.language-python:before {
	content: "Python";
	background-color: #212121;
	position: relative;
	top: 12px;
	left: 15px;
	
	padding: 5px 20px;
	border-radius: 30px;
	border: solid;
	border-color: #603175;
}

.language-plaintext:before {
	content: "Plain Text";
	background-color: #212121;
	position: relative;
	top: 12px;
	left: 15px;
	
	padding: 5px 20px;
	border-radius: 30px;
	border: solid;
	border-color: #603175;
}

.language-cpp:before {
	content: "C++";
	background-color: #212121;
	position: relative;
	top: 12px;
	left: 15px;
	
	padding: 5px 20px;
	border-radius: 30px;
	border: solid;
	border-color: #603175;
}

.language-csharp:before {
	content: "C#";
	background-color: #212121;
	position: relative;
	top: 12px;
	left: 15px;
	
	padding: 5px 20px;
	border-radius: 30px;
	border: solid;
	border-color: #603175;
}

.language-xml:before {
	content: "XML";
	background-color: #212121;
	position: relative;
	top: 12px;
	left: 15px;
	
	padding: 5px 20px;
	border-radius: 30px;
	border: solid;
	border-color: #603175;
}

.language-json:before {
	content: "JSON";
	background-color: #212121;
	position: relative;
	top: 12px;
	left: 15px;
	
	padding: 5px 20px;
	border-radius: 30px;
	border: solid;
	border-color: #603175;
}

.language-groovy:before {
	content: "Groovy";
	background-color: #212121;
	position: relative;
	top: 12px;
	left: 15px;
	
	padding: 5px 20px;
	border-radius: 30px;
	border: solid;
	border-color: #603175;
}

.col {
	margin-top: 0;
	margin-bottom: 0;
}

.card {
	width: 18em;
	height: 100%;
	box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.5);
}

.card-wrapper {
	height: 100%;
}

.card-thumb {
	height: 180px;
	overflow: hidden;
}

#sidebar {
	position: sticky;
	top: 90px;
}

ul.toc, ul.toc > li > ul, ul.toc > li > ul > li > ul {
	list-style-position: inside;
	list-style: none;
	padding: 0px;
}

.toc_entry > a {
	display: block;
	margin: 5px 0px;
	padding: 10px;
	width: 100%;
	
	border-radius: 10px;
	border: solid;
	background-color: #373535;
	border-color: #373535;
}

.toc_entry > a:hover {
	background-color: #464646;
	border-color: #464646;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-bottom: 15px;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.social-sm {
	display: inline-block;
	background-color: white;

	mask-repeat: no-repeat;
	mask-position: center;
	mask-size: contain;

	width: 1em;
	height: 1em;
}

.social-kofi {
	mask-image: url('/assets/images/kofi.svg');
}

.social-bluesky {
	mask-image: url('/assets/images/bluesky.svg');
}

.social-patreon {
	mask-image: url('/assets/images/patreon.svg');
}

.social-youtube {
	mask-image: url('/assets/images/youtube.svg');
}

.social-github {
	mask-image: url('/assets/images/github.svg');
}

.social-rss {
	mask-image: url('/assets/images/rss.svg');
}

.carousel-inner {
	aspect-ratio: 16/9;
}

.carousel-indicators img {
	width: 70px;
	height: 40px;
	display: block;
}

.carousel-indicators {
	[data-bs-target] {
		width: 70px;
		height: 40px;
	}
}

.carousel-indicators.active button {
	width: 70px;
	height: 40px;
}

.carousel-indicators{
	position: unset;
	height: 40px;
}

.yt-popup-button-overlay {
	pointer-events: none;
	position: absolute;
	
	width: 70px;
	height: 70px;

	top: 50%;
	left: 50%;

	transform:translate(-50%, -50%);
	
	mask-image: url('/assets/images/youtube.svg');

	display: inline-block;
	background-color: red;

	mask-repeat: no-repeat;
	mask-position: center;
	mask-size: contain;

	transition: .25s ease;
}

/* When you mouse over the container, fade in the overlay title */
button:hover .yt-popup-button-overlay {
	width: 90px;
	height: 90px;
	background-color: white;
}


.yt-popup-button-overlay-small {
	pointer-events: none;
	
	width: 30px;
	height: 30px;

	transform:translate(-0px, -35px);

	mask-image: url('/assets/images/youtube.svg');

	display: inline-block;
	background-color: red;

	mask-repeat: no-repeat;
	mask-position: center;
	mask-size: contain;
}

button:hover .yt-popup-button-overlay-small {
	background-color: white;
} 